/* override css from react-bootstrap-original.css */
.navbar {
  padding: auto;
  margin: auto;
  /* background-color: #0071c5; */
  background-color: #eeeeee;
  min-width: 300px;
  border-bottom: solid 0px #ccc;
}

.navbar-nav .nav-link {
  font-size: 16px;
  /* font-weight: bold; */
  /* border: solid 1px red; */
}

.navbar-expand-lg .navbar-nav .nav-link {
  /* border: solid 1px red; */
  padding: 0;
  margin: -4px 10px 0 10px;
  text-align: right;
}

.navbar-light .navbar-nav .nav-link {
  /* border: solid 1px red; */
  color: #000;
}

.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
  color: #ff00cc;
}

.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .show > .nav-link {
  color: #ff00cc;
}

.navbar-light .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(0, 0, 0, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

/* custom css starts here */

.navbar-logo-img {
  height: 45px;
  /* border:solid 1px red; */
  margin-top: 0px;
  padding: 0;
}
