.home-body {
  /* border: solid 2px red; */
}
video {
  height: 100%;
}
.video-container {
  width: 100%;
  background-size: cover;
  position: relative;
  background-color: #161b1f;
  display: flex;
  align-items: center;
  justify-content: center;
}

#video-landing {
  /* position: relative; */
  /* height: 555px; */
  /* this is the height of the landing page video , keep this the video message in place during refresh or loading of the page */
  background-color: #161b1f;
  /* background-size: cover; */
  width: 100%;
}

.video-message-container {
  text-shadow: 3px 3px 3px #000;
  color: white;
  width: 100%;
  position: absolute;
}

.video-message {
  font-size: 75px; /* fallback support */
  font-size: 3.8vw;
  font-family: 'ProximaNova';
}
.video_message_sub {
  font-size: 35px;
  font-size: 1.9vw;
}
.img-icon-home {
  /* vertical-align: text-top; */
  float: left;
  margin: 10px;
  max-height: 150px;
}
hr {
  border: solid 1px #ccc;
}

.img-generic-home {
  padding: 20px;
  max-width: 500px;
  width: 100%;
}
.textarea-custom-home {
  background-color: #eee;
  white-space: pre-wrap;
}
.textarea-custom-home::placeholder {
  color: #000;
}
.textarea-custom-home:-ms-input-placeholder {
  color: #000;
}
.textarea-custom-home::-webkit-input-placeholder {
  color: #000;
}
@media only screen and (min-width: 1200px) {
  .video-message {
    font-size: 75px;
  }
  .video_message_sub {
    font-size: 35px;
  }
}
@media only screen and (max-width: 768px) {
  #video-bg {
    height: auto;
  }
  .video_message_sub {
    display: none;
  }
  .video-message {
    font-size: 6.5vw;
  }
  .video_message_sub {
    font-size: 3vw;
  }
  .img-icon-home {
    vertical-align: text-top;
    margin: 10px;
    max-height: 120px;
    clear: both;
  }
}
