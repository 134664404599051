.body-dashboard {
  /* border: solid 1px red; */
  min-height: 500px;
  display: flex;
}
.container-dashboard {
  /* border: solid 1px green; */
  min-height: 100%;
}
.dashboard-icon {
  width: 150px;
  padding: 10px;
  margin: 10px;
  font-size: 40px;
}
.dashboard-icon:hover {
  filter: hue-rotate(90deg);
}
