.body-login {
  background-color: #fff;
  margin: 0;
  /* height: 100%; */
  overflow: hidden;
  width: 100%;
}
.box-account-login {
  background-color: #000;
  padding: 20px;
  border-radius: 5px;
  -moz-outline-radius: 5px;
}
.box-login {
  /* background-color:#edeff7; */
  margin: 100px auto 0px auto;
  max-width: 40%;
  border: 3px solid #ccc;
  padding: 10px;
  overflow: hidden;
  border-radius: 5px;
  -moz-outline-radius: 5px;
}

.box1-login {
  /* background-color:#edeff7; */
  margin: 55px auto;
  max-width: 348px;
  border: 1px solid #d0d0d0;
  padding: 20px;
  overflow: hidden;
  border-radius: 5px;
  -moz-outline-radius: 5px;
}

.box2-login {
  /* background-color:#edeff7; */
  margin: 55px auto;
  max-width: 480px;
  border: 1px solid #d0d0d0;
  padding: 20px;
  overflow: hidden;
  border-radius: 5px;
  -moz-outline-radius: 5px;
}

.col_custom_login {
  color: #fff;
}

.verify-email-box1-login {
  padding: 20px 10px;
  margin-top: 20px;
  max-width: 768px;
  border: solid 1px #ccc;
  background-color: #f5f0dd;
  text-align: left;
}

@media only screen and (max-width: 1000px) {
  .box1-login {
    width: 70%;
  }
}
