.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: #eee;
  /* border-top: solid 1px #ccc; */
  text-align: center;
  padding: 50px;
}

.footer a {
  /* border:solid px red;  */
  text-transform: capitalize;
  font-size: 17px;
  color: #636e74;
  /* font-weight: 600; */
}
.footer a:hover {
  color: #ff00fc;
  text-decoration: none;
}
