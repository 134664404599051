.slide-container {
  width: 70%;
  margin: auto;
}

h3 {
  text-align: center;
}

.each-slide > div {
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  height: 300px;
}

.each-slide span {
  padding: 20px;
  font-size: 20px;
  background: #efefef;
  text-align: center;
}

.each-fade {
  display: flex;
}

.each-fade .image-container {
  width: 75%;
  overflow: hidden;
}

.each-fade .image-container img {
  width: 100%;
}

.each-fade h2 {
  width: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  background: #adceed;
}
.page-body {
  margin: auto;
  width: 100%;
  padding: 30px;
}
