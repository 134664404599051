@font-face {
  font-family: 'ClanBook';
  src: local('ClanBook'), url('./../../assets/fonts/clan-book-webfont.ttf') format('truetype');
}

@font-face {
  font-family: 'ProximaNova';
  src: local('ProximaNova'), url('./../../assets/fonts/proxima-nova.ttf') format('truetype');
}

html,
body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  margin: 0;
  padding: 0;
  height: 100%;
}
/* helps with keeping footer from hiding content */
.clearfooter {
  clear: both;
  height: 200px;
}
#root {
  height: 100%;
}
#App-body {
  min-height: 100%;
  position: relative;
}
.body-group {
  padding: 80px 8px;
  overflow: hidden;
}
.group-content {
  justify-content: center;
  font-size: 20px;
}
.a-color-white {
  color: #fff;
}
.a-color-white:hover {
  color: #ff00fc;
  text-decoration: none;
}
.a-color {
  color: #000;
}
.a-color:hover {
  color: #ff00fc;
  text-decoration: none;
}

.common-link {
  text-decoration: none;
}
.common-link:hover {
  color: #ff00fc;
  text-decoration: none;
}
.font-face-clanbook {
  font-family: 'ProximaNova';
}
.input1_custom_index {
  border-bottom: solid 1px #999;
}
.input2_custom_index {
  border: solid 1px #999;
}
.h1_custom_index {
  font-family: 'ProximaNova';
  font-weight: bold;
  font-size: 2.5em;
}
.h2_custom_index {
  font-family: 'ProximaNova';
  font-weight: bold;
  font-size: 2em;
}
.h3_custom_index {
  font-family: 'ProximaNova';
  font-weight: bold;
  font-size: 1em;
}

.font-size-24 {
  font-size: 24px;
}
.font-size-18 {
  font-size: 18px;
}
.font-size-14 {
  font-size: 14px;
}
.underlinks {
  /* text-decoration: underline; */
  color: #000;
  border-bottom: 1px solid #000;
  font-weight: 600;
}
.underlinks:hover {
  text-decoration: none;
  color: #ff00fc;
  border-bottom: 1px solid #ff00fc;
}
.modalbox1-custom-index {
  border: solid 1px #ccc;
  max-width: 768px;
  margin: 50px auto;
  padding: 25px;
  border-radius: 10px;
  -moz-outline-radius: 10px;
}

@media only screen and (min-width: 768px) {
  .flexbox-container {
    display: flex;
    /* flex-wrap: wrap; */
  }
  .flexbox-1 {
    flex: 1;
    clear: both;
    padding: 10px;
  }
  .flexbox-2 {
    flex: 2;
    clear: both;
    padding: 10px;
  }
  .flexbox-3 {
    flex: 3;
    clear: both;
    padding: 10px;
  }
}

fieldset {
  border-top: 1px solid #ccc;
  width: auto;
  padding: 25px 20px;
  margin: 5px 0px;
  background-color: #edeff7;
  border-radius: 5px;
}

fieldset legend {
  margin-bottom: 0px;
  width: auto;
}
